import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layouts/main-layout";
import SportsLayout from "../../components/layouts/sports-layout";
import CourseInfo from "../../components/course-info";
import Seo from "../../components/seo";

const Wellenreiten = () => {
  const headerImage = (
    <StaticImage
      src="../../images/heroimages/tidens_website_WAV_header.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  const description = (
    <div>
      Die Urform des Surfens – nur du, dein Brett und das Wasser! Klingt simpel,
      doch ganz ohne körperliche Fitness, Geschicklichkeit und ein wenig Übung
      geht es nicht. Behalte immer das Ziel vor Augen, auch wenn es dir am
      Anfang unmöglich erscheint. Denn das Gefühl, deine erste Welle zu
      meistern, lässt dich alles andere vergessen. Und das Beste: Du musst gar
      nicht bis nach Hawaii, denn auch bei uns auf Langeoog kann man wunderbar
      Wellenreiten. Bist du bereit, dich in unseren Wellenreitkursen vom
      Surffieber packen zu lassen?
    </div>
  );

  const rentalDescription =
    "Du hast schon ausreichend Kenntnisse, um alleine zu üben? Dann kannst du dir bei uns auch Surfboards (sowohl Soft- als auch Hardboards) in verschiedenen Größen ausleihen.";

  const courses = [
    <CourseInfo
      title="Schnupperkurs"
      price="50"
      duration="1,5h"
      people="10"
      description="In diesem Kurs erfährst du, wie es sich anfühlt, die ersten Meter auf einem Surfboard zu fahren. Hierfür bleiben wir in Strandnähe und toben uns in den schon gebrochenen Wellen im stehtiefen Wasser aus. Der Schnupperkurs ermöglicht schnelle Erfolgserlebnisse und ist auch hervorragend für Kinder geeignet."
      sport="WAS"
      type="TRY"
      requestOnly={false}
    />,
    <CourseInfo
      title="Einsteigerkurs"
      price="95"
      duration="3h (2 Tage)"
      people="8"
      description="Bereit für deine erste Welle? Im Einsteigerkurs Wellenreiten lernst du die ersten Grundlagen, die du zum Abreiten der Welle benötigst: das Lesen der Welle, die richtige Paddeltechnik und das Aufstehen auf dem Board."
      sport="WAS"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Grundkurs"
      price="215"
      duration="7,5h (5 Tage)"
      people="8"
      description="Bereit für deine ersten Wellen? Im Grundkurs Wellenreiten lernst du alle Grundlagen, die du zum Abreiten der Welle benötigst: das Lesen der Welle, die richtige Paddeltechnik, das Aufstehen auf dem Board, den stabilen Stand und die Steuerung deines Surfboards."
      sport="WAS"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Aufbaukurs"
      price="50"
      duration="1,5h (1 Tag)"
      people="8"
      description="Wenn du schon erste Erfahrungen im Wellenreiten hast, bist du im Aufbaukurs richtig. Hier  konzentrieren wir uns vor allem darauf, deine Technik zu verbessern. Die Lerninhalte werden dabei ganz auf deine Bedürfnisse zugeschnitten."
      sport="WAS"
      type="ADV"
      requestOnly={true}
    />,
    <CourseInfo
      title="Privatstunde"
      price="99"
      duration="1h"
      people="1"
      description="Du möchtest etwas ganz Bestimmtes lernen oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne unterrichten wir dich auch in einer Privatstunde. Bitte beachte, dass wir Privatstunden nur auf Anfrage anbieten können"
      requestOnly={true}
    />,
  ];

  const image1 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery1.jpg"
      alt="Wellenreiten Kurse bei Tidens Surfhuus Galerybild 1"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image2 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery2.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image3 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery3.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image4 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery4.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image5 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery5.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image6 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery6.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={465}
      height={700}
      orientation="portrait"
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image7 = (
    <StaticImage
      src="../../images/galery/wave/tidens_WAV_galery7.jpg"
      alt="Wellenreiten lernen bei Tidens Surfhuus Galerybild 2"
      width={465}
      height={700}
      orientation="portrait"
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const images = [image1, image7, image6, image5, image2, image3, image4];

  return (
    <Layout>
      <Seo
        title="Wellenreiten Kurse für Anfänger und Fortgeschrittene"
        description="Du möchtest Wellenreiten lernen? Wir bieten Wellenreit Kurse für Anfänger und Fortgeschrittene auf der Nordseeinsel Langeoog an."
      />
      <SportsLayout
        title="Wellenreiten"
        sport="wellenreiten"
        image={headerImage}
        description={description}
        rental_description={rentalDescription}
        courses={courses}
        images={images}
      />
    </Layout>
  );
};

export default Wellenreiten;
